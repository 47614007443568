const GenderOptions = [
  {key: 'GENDER_MALE', name: 'Nam'},
  {key: 'GENDER_FEMALE', name: 'Nữ'},
  {key: 'GENDER_NONE_UNSPECIFIED', name: 'Không xác định'},
];

const defaultGender = 'GENDER_NONE_UNSPECIFIED';

const profileUpdateNotifications = {
  success: 'Cập nhật thông tin thành công!',
};

const rulesUpdateInfoUserForm = {
  fullname: [{required: true, message: 'Vui lòng nhập họ và tên!'}],
  birthDate: [{required: true, message: 'Vui lòng nhập ngày sinh!'}],
  gender: [{required: true, message: 'Vui lòng chọn giới tính!'}],
  mobileNo: [
    {
      required: true,
      message: 'Vui lòng nhập số điện thoại!',
    },
    {
      pattern: /^[0-9\s]*$/,
      message: 'Số điện thoại chỉ có thể chứa kí tự số!',
    },
  ],
  email: [
    {
      required: true,
      message: 'Vui lòng nhập email!',
    },
    {
      type: 'email',
      message: 'Địa chỉ email không hợp lệ!',
    },
  ],
};

const rulesChangePasswordForm = {
  oldPassword: [
    {
      required: true,
      message: 'Vui lòng nhập mật khẩu cũ!',
    },
    {
      min: 7,
      message: 'Mật khẩu phải có ít nhất 7 kí tự!',
    },
    {
      max: 32,
      message: 'Mật khẩu không được quá 32 kí tự!',
    },
  ],
  newPassword: [
    {
      required: true,
      message: 'Vui lòng nhập mật khẩu mới!',
    },
    {
      min: 7,
      message: 'Mật khẩu phải có ít nhất 7 kí tự!',
    },
    {
      max: 32,
      message: 'Mật khẩu không được quá 32 kí tự!',
    },
  ],
  confirmNewPassword: [
    {
      required: true,
      message: 'Vui lòng nhập xác nhận mật khẩu mới!',
    },
    {
      min: 7,
      message: 'Mật khẩu phải có ít nhất 7 kí tự!',
    },
    {
      max: 32,
      message: 'Mật khẩu không được quá 32 kí tự!',
    },
  ],
};

const alertDefault = {
  type: '',
  message: '',
  show: false,
};

const alertChangePassword = {
  success: {
    type: 'success',
    message: 'Cập nhật mật khẩu thành công!',
    show: true,
  },
  errorPasswordInvalid: {
    type: 'error',
    message: 'Mật khẩu cũ không đúng vui lòng kiểm tra lại!',
    show: true,
  },
};

// eslint-disable-next-line import/prefer-default-export
export {
  profileUpdateNotifications,
  rulesUpdateInfoUserForm,
  GenderOptions,
  defaultGender,
  rulesChangePasswordForm,
  alertChangePassword,
  alertDefault,
};
