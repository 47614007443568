import React from 'react';
import Bell from '~components/icons/bell';
import CreditCard from '~components/icons/credit-card';
import Gift from '~components/icons/gift';
import Heart from '~components/icons/heart';
import Lock from '~components/icons/lock';
import Logout from '~components/icons/log-out';
import MyWallet from '~components/icons/my-wallet';
import Pin from '~components/icons/pin';
import Profile from '~components/icons/profile';
import Time from '~components/icons/time';

// eslint-disable-next-line import/prefer-default-export
export const sidebarAccountMenus = [
  {
    name: 'Thông tin cá nhân',
    icon: <Profile />,
    link: '/account/profile',
  },
  {
    name: 'Địa chỉ giao hàng',
    icon: <Pin />,
    link: '/account/address',
  },
  {
    name: 'Thông tin thanh toán',
    icon: <CreditCard />,
    link: '',
  },
  {
    name: 'Ví của tôi',
    icon: <MyWallet />,
    link: '/account/my-wallet',
  },
  {
    name: 'Đổi mật khẩu',
    icon: <Lock />,
    link: '/account/change-password',
  },
  {
    name: 'Lịch sử đơn hàng',
    icon: <Time />,
    link: '/account/order-history',
  },
  {
    name: 'Thông báo của tôi',
    icon: <Bell />,
    link: '/account/notification',
  },
  {
    name: 'Sản phẩm yêu thích',
    icon: <Heart />,
    link: '/account/favorite',
  },
  {
    name: 'Kho voucher',
    icon: <Gift />,
    link: '',
  },
  {
    name: 'Đăng xuất',
    icon: <Logout />,
    link: '',
  },
];

export const requestDeleteNotify = {
  ids: [],
  isDeleteRead: false,
  isDeleteAll: false,
};

export const allDataLimit = {
  'pagination.limit': 1000,
};
