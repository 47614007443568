import React from 'react';
import './styles.scss';
import ChevronRight from '~components/icons/chevron-right';
import {Link} from 'gatsby';
import {sidebarAccountMenus} from '~constants/account';

const AccountSideBar = ({activeItemURL}) => {
  return (
    <div id='sidebar-account'>
      <div className='header'>Thông tin tài khoản</div>
      <div className='menu'>
        {sidebarAccountMenus.map((item, i) => (
          <div key={i} className='link-item'>
            <Link to={item.link}>
              <div className='item'>
                <div className='icon'>{item.icon}</div>
                <div className='name'>{item.name}</div>
                <div className={`${item.link !== activeItemURL && 'not-active'}`}>
                  <ChevronRight />
                </div>
              </div>
              {i < sidebarAccountMenus.length - 1 && <div className='border' />}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountSideBar;
