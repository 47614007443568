import React from 'react';
import preloading from '~images/preloading.gif';

import './styles.scss';

const Loading = ({loading}) => {
  return (
    <div hidden={!loading} className='loader-container'>
      <div className='loader'>
        <img src={preloading} alt='loading' width={120} height={120} />
      </div>
    </div>
  );
};

export default Loading;
