import React from 'react';

const Time = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 20 20'>
      <path fill='#828282' d='M7 5h2v5h5v2H7V5z' />
      <path
        fill='#828282'
        fillRule='evenodd'
        d='M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10zm-2 0a8 8 0 11-16 0 8 8 0 0116 0z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export default Time;
