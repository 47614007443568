import React from 'react';

const AccountHeader = ({title}) => {
  return (
    <div
      style={{
        fontFamily: 'Quicksand',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '34px',
        lineHeight: '42px',
        marginBottom: '20px',
      }}
    >
      {title}
    </div>
  );
};

export default AccountHeader;
