import {Form, Input, Button, Alert} from 'antd';
import React, {useState} from 'react';
import {rulesChangePasswordForm, alertChangePassword, alertDefault} from '~constants/consumer';
import {post} from '~utils/api';

import * as styles from './styles.module.css';

const ChangePasswordContainer = () => {
  const [alert, setAlert] = useState(alertDefault);
  const [form] = Form.useForm();

  const onFinish = async values => {
    try {
      const request = {
        currentPass: values.oldPassword,
        newPass: values.newPassword,
        newPassRepeat: values.confirmNewPassword,
      };
      const res = await post(`/v1/user/change-password`, request);
      if (!res) {
        setAlert(alertChangePassword.errorPasswordInvalid);
      } else {
        setAlert(alertChangePassword.success);
        form.resetFields();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <div className={styles.changePasswordContainer}>
      <div className={styles.main}>
        {alert.show && (
          <Alert
            className={styles.errMessage}
            message={alert.message}
            type={alert.type}
            showIcon
            closable
            onClose={() => setAlert(alertDefault)}
          />
        )}
        <Form form={form} layout='vertical' onFinish={onFinish} autoComplete='off'>
          <Form.Item label='Mật khẩu cũ' name='oldPassword' rules={rulesChangePasswordForm.oldPassword}>
            <Input type='password' placeholder='Nhập mật khẩu cũ' />
          </Form.Item>
          <Form.Item
            label='Mật khẩu mới'
            name='newPassword'
            dependencies={['oldPassword']}
            rules={[
              ...rulesChangePasswordForm.newPassword,
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!value || getFieldValue('oldPassword') !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Mật khẩu mới không được trùng mật khẩu cũ!'));
                },
              }),
            ]}
          >
            <Input type='password' placeholder='Nhập mật khẩu mới' />
          </Form.Item>
          <Form.Item
            label='Xác nhận mật khẩu mới'
            name='confirmNewPassword'
            dependencies={['newPassword']}
            rules={[
              ...rulesChangePasswordForm.confirmNewPassword,
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Xác nhận mật khẩu mới chưa trùng khớp!'));
                },
              }),
            ]}
          >
            <Input type='password' placeholder='Nhập xác nhận mật khẩu mới' />
          </Form.Item>
          <Form.Item>
            <Button size='large' type='primary' htmlType='submit'>
              Cập nhật
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ChangePasswordContainer;
