import {Breadcrumb} from 'antd';
import {Link} from 'gatsby';
import React from 'react';

export default function CusBreadcrumbs({items}) {
  return (
    <Breadcrumb separator='>'>
      <Breadcrumb.Item>
        <Link to='/home'>Trang chủ</Link>
      </Breadcrumb.Item>
      {items.map(item => (
        <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}
