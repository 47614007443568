import React from 'react';

const ChevronRight = ({fill = '#D8D6D6', size = '12'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 8 12'
    width={size}
    height={size}
    fill={fill}
  >
    <path d='M1.70504 0L0.295044 1.41L4.87504 6L0.295044 10.59L1.70504 12L7.70504 6L1.70504 0Z' />
  </svg>
);

export default ChevronRight;
