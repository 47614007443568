import React from 'react';

const Heart = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 22 20'>
      <path
        fill='#828282'
        fillRule='evenodd'
        d='M11.013 3.572L9.926 2.485a5.5 5.5 0 10-7.778 7.778l8.839 8.839.002-.002.026.026 8.839-8.839a5.5 5.5 0 00-7.778-7.778l-1.063 1.063zm-.024 12.7l4.936-4.937 1.45-1.4h.002l1.063-1.062a3.5 3.5 0 00-4.95-4.95L11.014 6.4l-.007-.007h-.001L8.512 3.9a3.5 3.5 0 10-4.95 4.95l2.54 2.54.001-.003 4.886 4.886z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export default Heart;
