import React from 'react';

const Logout = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='16' fill='none' viewBox='0 0 20 16'>
      <path
        fill='#828282'
        d='M6.514 16h-4a2 2 0 01-2-2V2a2 2 0 012-2h4v2h-4v12h4v2zM11.841 13.385l1.42-1.408-3.919-3.953h9.143a1 1 0 100-2H9.323l3.98-3.947-1.408-1.42-6.39 6.337 6.336 6.391z'
      />
    </svg>
  );
};

export default Logout;
