import React from 'react';

const Lock = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='20' fill='none' viewBox='0 0 18 22'>
      <path
        fill='#828282'
        fillRule='evenodd'
        d='M15 9.5a3 3 0 013 3v6a3 3 0 01-3 3H3a3 3 0 01-3-3v-6a3 3 0 013-3v-3a6 6 0 1112 0v3zm-6-7a4 4 0 014 4v3H5v-3a4 4 0 014-4zm6 9H3a1 1 0 00-1 1v6a1 1 0 001 1h12a1 1 0 001-1v-6a1 1 0 00-1-1z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export default Lock;
