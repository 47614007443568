import React, {useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import {connect} from 'react-redux';
import {relogin} from '~actions/user';
import Loading from '~components/page-loader';

const ConsumerAuth = ({getUser, consumer, children}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (consumer && consumer.id) return;

    const getCurUser = async () => {
      try {
        setLoading(true);
        const res = await getUser();
        if (!res) navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    getCurUser();
  }, [consumer]);

  return (
    <>
      <Loading loading={loading} />
      {children}
    </>
  );
};

export default connect(
  state => ({
    consumer: state.user.consumer,
  }),
  dispatch => ({
    getUser: () => dispatch(relogin()),
  })
)(ConsumerAuth);
