import React from 'react';

const CreditCard = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='16' fill='none' viewBox='0 0 24 18'>
      <path fill='#828282' d='M4 6a1 1 0 011-1h4a1 1 0 010 2H5a1 1 0 01-1-1z' />
      <path
        fill='#828282'
        fillRule='evenodd'
        d='M4 0a4 4 0 00-4 4v10a4 4 0 004 4h16a4 4 0 004-4V4a4 4 0 00-4-4H4zm16 2H4a2 2 0 00-2 2v7h20V4a2 2 0 00-2-2zm2 11H2v1a2 2 0 002 2h16a2 2 0 002-2v-1z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export default CreditCard;
