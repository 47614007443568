import React from 'react';

const Pin = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='20' fill='none' viewBox='0 0 18 22'>
      <path
        fill='#828282'
        fillRule='evenodd'
        d='M13.272 9.272a4 4 0 11-8 0 4 4 0 018 0zm-2 0a2 2 0 11-4 0 2 2 0 014 0z'
        clipRule='evenodd'
      />
      <path
        fill='#828282'
        fillRule='evenodd'
        d='M2.794 15.518a9 9 0 1112.724-.312l-6.206 6.518-6.518-6.206zm11.276-1.691l-4.827 5.07-5.07-4.827a7 7 0 119.897-.243z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export default Pin;
