import React from 'react';

const Profile = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 22 22'>
      <path
        fill='#828282'
        fillRule='evenodd'
        d='M15 8a4 4 0 11-8 0 4 4 0 018 0zm-2 0a2 2 0 11-4 0 2 2 0 014 0z'
        clipRule='evenodd'
      />
      <path
        fill='#828282'
        fillRule='evenodd'
        d='M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zM2 11c0 2.09.713 4.014 1.908 5.542A8.986 8.986 0 0111.065 13a8.984 8.984 0 017.092 3.458A9 9 0 102 11zm9 9a8.963 8.963 0 01-5.672-2.012A6.992 6.992 0 0111.065 15a6.991 6.991 0 015.689 2.92A8.964 8.964 0 0111 20z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export default Profile;
